// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the terms of the LICENSE file distributed with this project.

.#{$ns}-context-menu .#{$ns}-popover-target {
  display: block;
}

.#{$ns}-context-menu-popover-target {
  position: fixed;
}
