// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the terms of the LICENSE file distributed with this project.

@import "./common";

// react-day-picker does not conform to our naming scheme
// stylelint-disable selector-class-pattern
.#{$ns}-daterangepicker {
  display: flex;
  white-space: nowrap;

  .DayPicker:not(:last-child) {
    margin-right: $pt-grid-size;
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .#{$ns}-daterangepicker-shortcuts + .DayPicker {
    border-left: 1px solid $pt-divider-black;
    min-width: $datepicker-min-width + $pt-grid-size;
    padding-left: $pt-grid-size;
  }

  // ensure min-widths are set correctly for variants of contiguous months, single month, and shortcuts
  &.#{$ns}-daterangepicker-contiguous {
    .DayPicker {
      min-width: ($datepicker-min-width * 2) + $pt-grid-size;
    }

    .#{$ns}-daterangepicker-shortcuts + .DayPicker {
      min-width: ($datepicker-min-width + $pt-grid-size) * 2;
    }

    .DayPicker-Month:not(:last-child) {
      margin-right: $pt-grid-size;
    }
  }

  &.#{$ns}-daterangepicker-single-month {
    .DayPicker {
      min-width: $datepicker-min-width;
    }

    .#{$ns}-daterangepicker-shortcuts + .DayPicker {
      min-width: $datepicker-min-width + $pt-grid-size;
    }
  }

  .DayPicker-Day {
    // we only want outside days to be shown when displaying one month at a time
    // https://github.com/palantir/blueprint/pull/586/files#r98813760
    &--outside {
      visibility: hidden;
    }

    &--hovered-range {
      border-radius: 0;

      // need to disable hover styles for all variants of selected dates
      // stylelint-disable max-line-length
      &:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
        background-color: $daterangepicker-range-background-color;
      }
      // stylelint-enable max-line-length
    }

    &--selected-range {
      border-radius: 0;
      background-color: $daterangepicker-range-background-color-selected;

      &:hover {
        background-color: $daterangepicker-range-background-color-selected-hover;
      }
    }

    // need to set rounded corners
    // stylelint-disable max-line-length
    &--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    // stylelint-enable max-line-length

    &--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--hovered-range-end:not(.DayPicker-Day--hovered-range-start) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.#{$ns}-menu.#{$ns}-daterangepicker-shortcuts {
  display: inline-block;
  margin-top: -$pt-grid-size / 2;
  margin-left: -$pt-grid-size / 2;
  min-width: $pt-grid-size * 15;
  padding-top: 0;
  padding-right: $pt-grid-size / 2;
  padding-left: 0;
}
