$timepicker-input-row-height: $pt-grid-size * 3 !default;
// subtract two because of inset shadow
$timepicker-input-row-inner-height: $timepicker-input-row-height - 2 !default;
// helps focus states of inputs line up correctly
$timepicker-row-padding: 0 1px !default;
$timepicker-divider-width: $pt-grid-size * 1.1 !default;
$timepicker-control-width: $pt-grid-size * 3.3 !default;

.#{$ns}-timepicker {
  white-space: nowrap;

  .#{$ns}-timepicker-arrow-row {
    padding: $timepicker-row-padding;
  }

  .#{$ns}-timepicker-arrow-button {
    display: inline-block;
    width: $timepicker-control-width;
    padding: ($pt-grid-size * 0.4) 0;
    text-align: center;

    + .#{$ns}-timepicker-arrow-button {
      margin-left: $timepicker-divider-width;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .#{$ns}-timepicker-input-row {
    display: inline-block;
    border-radius: $pt-border-radius;
    box-shadow: $pt-input-box-shadow;
    background: $input-background-color;
    height: $timepicker-input-row-height;
    padding: $timepicker-row-padding;
    vertical-align: middle;
    line-height: $timepicker-input-row-inner-height;
  }

  .#{$ns}-timepicker-divider-text {
    display: inline-block;
    width: $timepicker-divider-width;
    text-align: center;
    color: $pt-text-color-muted;
    font-size: $pt-font-size-large;
  }

  .#{$ns}-timepicker-input {
    outline: 0;
    border: 0;
    border-radius: $pt-border-radius;
    box-shadow: input-transition-shadow($input-shadow-color-focus);
    background: transparent;
    width: $timepicker-control-width;
    height: $timepicker-input-row-inner-height;
    padding: 0;
    text-align: center;
    color: $pt-text-color;
    transition: $input-transition;

    &:focus {
      box-shadow: input-transition-shadow($input-shadow-color-focus, true);
    }
  }

  .#{$ns}-timepicker-ampm-select {
    margin-left: $pt-grid-size / 2;
  }

  &.#{$ns}-disabled {
    .#{$ns}-timepicker-input-row {
      @include pt-input-disabled();
    }

    .#{$ns}-timepicker-input,
    .#{$ns}-timepicker-divider-text {
      cursor: not-allowed;
      color: $input-color-disabled;
    }

    .#{$ns}-timepicker-arrow-button,
    .#{$ns}-timepicker-arrow-button:hover {
      cursor: not-allowed;
      color: $input-color-disabled;
    }
  }
}
