// Copyright 2018 Palantir Technologies, Inc. All rights reserved.
// Licensed under the terms of the LICENSE file distributed with this project.

.b-overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
}

.b-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px;
}
