@import "~@fontsource/clear-sans/all-400.css";
@import "src/firefly/style/_defaults.scss";
@import "settings";
@import "src/firefly/style/_firefly.scss";
@import "mixin";
@import "transition";

a {
  &:active {
    outline-offset: 2px;
    outline: rgba(flexy-color(), 0.5) solid 3px;
  }
}

.logo__image {
  width: 100px;
  height: auto;
}

@import 'block/app';
@import 'block/contest';
@import "block/body";
@import "block/partner";
@import "block/menu";
@import "block/main";
@import "block/toggle-link";
@import "block/detail-view";
@import "block/header";
@import "block/icon";
@import "block/wrapper";
@import "block/text";
@import "block/image-list";
@import "block/heading";
@import "block/notification";
@import "block/error";
@import "block/link";
@import "block/toolbar";
@import "block/dropzone";
@import "block/warning";

input::-webkit-file-upload-button {
  display: none;
}

.select-container {
  div[class$="-control"] {
    border: none;
    border-radius: 0.18571rem;
    box-shadow: 0 0 0 2px #e8e8e8 inset !important;

    &:hover,&:focus,&:active {
      border-color: #fc6401 !important;
      box-shadow: 0 0 0 2px #fc6401 inset !important;
    }
  }

  div[class$="-menu"] {
    background: white;
    max-height: 120px;
    > div {
      max-height: 120px !important;
      overflow-y: scroll !important;
      
      div[class$="-option"] {
        &:focus, &:active {
          background: #ffd0b3;
        }
      }
    }
  }
}

.react-autosuggest__container {
  position: relative;
  margin-bottom: 2px;
}

.react-autosuggest {
  &__input {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0.5em;
    border: none;
    border-radius: 0.18571rem;
    outline: none;
    background-color: #fff;
    color: #000;
    font-family: inherit;
    font-size: 1em;
    font-weight: 400;
    resize: vertical;
    box-sizing: border-box;
    box-shadow: 0 0 0 2px #e8e8e8 inset;
    cursor: pointer;
    transition: box-shadow .2s ease;

    &:focus {
      box-shadow: 0 0 0 2px #fc6401 inset;
    }
  }

  &__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: 36px;
      width: 280px;
      border: 1px solid #fc6401;
      background-color: #fff;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
    }    
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__suggestion {
    cursor: pointer;
    padding: 10px 20px;

    &--highlighted {
      background-color: #fc640133;
    }
  
  }

}

.row {
  display: flex;
  flex-direction: row;
}

.col-12 {
  width: 100%;
  flex: 1 1 auto;
}

.col-11 {
  width: 91.66666667%;
  flex: 1 1 auto;
}

.col-10 {
  width: 83.33333333%;
  flex: 1 1 auto;
}

.col-9 {
  width: 75%;
  flex: 1 1 auto;
}

.col-8 {
  width: 66.66666667%;
  flex: 1 1 auto;
}

.col-7 {
  width: 58.33333333%;
  flex: 1 1 auto;
}

.col-6 {
  width: 50%;
  flex: 1 1 auto;
}

.col-5 {
  width: 41.66666667%;
  flex: 1 1 auto;
}

.col-4 {
  width: 33.33333333%;
  flex: 1 1 auto;
}

.col-3 {
  width: 25%;
  flex: 1 1 auto;
}

.col-2 {
  width: 16.66666667%;
  flex: 1 1 auto;
}

.col-1 {
  width: 8.33333333%;
  flex: 1 1 auto;
}

.row > div {
  margin-left: 0.7em;
}

.row > div:nth-child(1) {
  margin-left: 0;
}
