.detail-view {
  &__panel {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-gap: 1rem;
  }

  &__buttons {
    text-align: right;
  }
}
