.main {
  margin-bottom: 2rem;

  &__input {

  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    padding: .4rem 1rem;
  }
}
