$popover-width: $pt-grid-size * 35 !default;

.b-popover {
  @include popover-sizing($arrow-square-size: 30px, $arrow-offset: 4px, $arrow-target-offset: -4px);
  @include popover-appearance($popover-background-color, inherit, $pt-popover-box-shadow, $pt-drop-shadow-opacity, $pt-border-shadow-opacity);
  @include scale-transition();
  display: inline-block;
  z-index: $pt-z-index-overlay;
  border-radius: $pt-border-radius;

  &--content {
    // only inline popovers get a width if this class is applied.
    // note that an inline overlay will be an immediate next sibling
    // of the popover target as of Blueprint 2.0.
    .b-popover-target + .b-overlay & {
      width: $popover-width;
    }
  }

  &__content {
    background-color: #fff;
    position: relative;
    border-radius: $pt-border-radius;
    max-width: $popover-width;
    padding: 1rem;

    &--minimal {
      padding: 0;
    }

    &--menu {
      padding: .4rem;
    }
  }

  &__backdrop {
    background: rgba($white, 0);
  }
}

// the box-shadow under the arrow SVG paths
.b-popover-arrow::before {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border-radius: $pt-border-radius - 1;
  content: "";
}

// remove arrow when popover is pinned inside its container
.tether-pinned .b-popover-arrow {
  display: none;
}

.transition-container {
  @include fade-transition();

  // ensure element size exactly equals its children, no edge-case 4px spacing!
  // (try removing this property with Slider content in example)
  display: flex;
  z-index: $pt-z-index-overlay;

  &:focus {
    outline: none;
  }

  // popover content should ignore pointer events during a popover's exit transition
  &.b-popover-leave .b-popover-content {
    pointer-events: none;
  }

  // Popper.js applies this attribute when the target fully leaves boundaries
  &[data-x-out-of-boundaries] {
    display: none;
  }
}

span.b-popover-target {
  // avoid `inline` elements as they won't account for padding etc
  display: inline-block;

  // this is important for span tag as default inline display height only includes text.
  // div tag can be used for display: block, which works fine.
}
