.link {
  text-decoration: none;
  color: flexy-color(text);
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: flexy-color();
  }

  &:not(:first-child) {
    margin-left: 1rem;
  }
}
