.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  &__menubtn {
    display: block;
  }

  &__select {
    width: 150.0px;
  }
}
