// border-radius по умолчанию берется из настроек темы
$flexy-block-badge-border-radius: $flexy-theme-border-radius !default;
// размер шрифта по умолчанию
$flexy-block-badge-font-size: 0.75rem !default;
// цвета по умолчанию берутся из темы
$flexy-block-badge-colors: $flexy-colors !default;

.b-badge {
  border-radius: $flexy-block-badge-border-radius;
  font-size: $flexy-block-badge-font-size;
  padding: .2em .6em;
  font-weight: 400;
  display: inline-block;
  color: #fff;
  box-sizing: border-box;
  position: relative;

  @include flexy-colors($flexy-block-badge-colors) {
    background: {
      color: $flexy-current-color;
    }
  }
}
