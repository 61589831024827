$flexy-block-heading-caption-color: #ccc !default;
$flexy-block-heading-text-color: #000 !default;

$flexy-theme-border-radius: 0.18571429rem !default;
$flexy-theme-border-size: 1px !default;
$flexy-theme-focus-border-size: 2px !default;
$flexy-theme-height: 32px !default;

$flexy-block-button-border-radius: $flexy-theme-border-radius !default;
$flexy-block-button-background-color: #96a8b2 !default;
$flexy-block-button-outline-width: 1px !default;
$flexy-block-input-border-radius: $flexy-theme-border-radius !default;
$flexy-block-input-border-color: #e8e8e8 !default;
$flexy-block-input-focus-color: map-get($flexy-colors, default) !default;
$flexy-block-input-hover-color: rgba($flexy-block-input-focus-color, 0.5) !default;

//$flexy-label-color: #393f49 !default;
$flexy-label-color: rgba(0, 0, 0, .65) !default;

$flexy-theme-avatar: (
  default: (background-color: #2c3e50, color: #fff),
  primary: (background-color: map-get($flexy-colors, primary), color: #fff),
  error: (background-color: map-get($flexy-colors, error), color: #fff),
  success: (background-color: map-get($flexy-colors, success), color: #fff)
) !default;

$flexy-block-tab-header-shadow: inset 0 -2px 0 0 rgba(#000, 0.05) !default;
$flexy-block-tab-color: flexy-color() !default;
$flexy-block-tab-shadow: inset 0 -2px 0 0 $flexy-block-tab-color !default;
$flexy-block-tab-disabled-color: #96a8b2 !default;
$flexy-block-tab-disabled: (color: #ccc) !default;
$flexy-block-tab-active: () !default;
$flexy-block-space-sizes: (
  small: 0.5em,
  default: 1em,
  large: 2em,
  xlarge: 4em,
  xxlarge: 6em
) !default;
$flexy-input-select-dropdown: 'data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==' !default;

$flexy-block-input-background-color: #fff !default;
$flexy-block-input-color: #000 !default;
