input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: normal;

  /* Browsers have different default form fonts */
  font-size: $flexy-body-font-size;
  font-family: $flexy-font;
}

/* Box Sizing Reset
_______________________-*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='checkbox'],
input[type='radio'],
select {
  box-sizing: border-box;
}

button,
input,
textarea,
select {

  /* Remove the stupid outer glow in Webkit */
  outline: 0;
}

/* Button Controls
_______________________-*/

input {
  &[type='checkbox'],
  &[type='radio'] {
    width: 13px;
    height: 13px;
  }

  &[type='search'] {

    /* Make webkit render the search input like a normal text field */
    appearance: textfield;
    box-sizing: content-box;
  }
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
_______________________-*/

button,
input[type='reset'],
input[type='button'],
input[type='submit'] {

  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
_______________________-*/

textarea {

  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
_______________________-*/

select {
  &[multiple] {

    /* Move the label to the top */
    vertical-align: top;
  }
}

::placeholder {
  font-size: $flexy-body-font-size;
  color: $flexy-placeholder-color;
  font-family: $flexy-font;
  font-style: normal;
}
