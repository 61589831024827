$overlay-background-color: rgba($black, 0.7) !default;

// restricts scrolling of underlying content while the overlay is open
body.b-overlay--open {
  overflow: hidden;
}

.b-overlay {
  // 0-out all positions so page won't jump when position changes (0s already there)
  @include position-all(static, 0);
  z-index: $pt-z-index-overlay;

  &:not(&--open) {
    // because of the 0-position covering the viewport,
    // we must ignore the mouse when not open
    pointer-events: none;
  }

  &--scroll {
    // scroll container covers the entire viewport
    position: fixed;
    overflow: auto;
  }

  &--scroll-inline {
    // when rendered inline, we want the overlay to position itself relative to
    // its parent container, not relative to the whole window. thus, we change
    // to position:absolute.
    position: absolute;
  }

  &--inline {
    display: inline;
    // inline overlays can overflow container by default (see Dialog & Popover)
    overflow: visible;
  }

  // this class is added to each Overlay child so that users won't need to
  // explicitly manage the position mode for inline and non-inline rendering.
  &__content {
    // default fixed pulls it out of the flow and makes it viewport-relative
    position: fixed;
    z-index: $pt-z-index-overlay;

    .b-overlay--inline &,
    .b-overlay--scroll & {
      // but inline (or scrollable) overlays want their children to respect
      // the parent positioning context. also allows the content to scroll.
      position: absolute;
    }
  }

  // fixed position so the backdrop forecefully covers the whole screen
  &__backdrop {
    @include position-all(fixed, 0);
    @include react-transition("b-overlay", (opacity: 0 1), 100ms * 2, $before: "&");
    z-index: $pt-z-index-overlay;
    background-color: $overlay-background-color;
    overflow: auto;
    user-select: none;

    &:focus {
      outline: none;
    }

    // as mentioned above: when inline, Overlay backdrop must respect parent
    .b-overlay--inline & {
      position: absolute;
    }
  }
}
